.main-page {
  position: relative;

  .main-page-search-container {
    padding-top: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 8px;
    margin-right: 8px;

    @media (min-width: 768px) {
      padding-top: 230px;
    }

    &.isIos {
      padding-top: 250px;
    }

    .row {
      justify-content: center;
      div[class^='col-'] {
        display: flex;
        justify-content: center;
        @media (min-width: 550px) {
          display: block;
          justify-content: flex-start;
        }
      }
    }

    .dropdown-container {
      display: flex;
      justify-content: center;
      .dropdown-menu {
        z-index: 9999;
      }
    }
  }

  .main-page-list-container {
    .container {
      .list-length {
        display: flex;
        justify-content: center;
        padding-top: 10px;
        padding-bottom: 25px;
        .list-entries {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          .list-lengt-text {
            color: #70b8d4;
            margin: 0;
          }
        }
      }
    }
  }

  .loading-spinner {
    padding-top: 100px;
    display: flex;
    justify-content: center;
  }

  .menu-drawer {
    width: 250px;
  }

  .add-button {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 1;
    button {
      width: 70px;
      height: 70px;
      border-radius: 50px;
      opacity: 0.75;
    }
  }
}
