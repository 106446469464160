$primary-text-color: #2a6d88;
$seconday-color: #c1a661;

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .footer {
    bottom: 0;
    @media (min-height: 710px) {
      position: fixed;
    }

    .container {
      padding-top: 0;
    }
  }
  .card {
    color: $primary-text-color !important;
    margin: 50px 0 150px;

    button {
      background-color: $primary-text-color ;
      border: 1px solid $primary-text-color ;
    }
  }

  .card-header {
    color: $primary-text-color !important;
  }

  .loading-spinner {
    padding-top: 250px;
    display: flex;
    justify-content: center;
  }

  .container {
    padding-top: 100px;

    @media (min-width: 768px) {
      padding-top: 100px;
    }
  }

  .row {
    justify-content: center;
  }

  .login-headline {
    padding: 30px;
  }
  .login-form {
    display: flex;

    input {
      outline: none;
    }
  }

  .login-register {
    padding: 10px 30px;
    a {
      color: $seconday-color;
      background-color: transparent;
      font-weight: bold;
    }
  }

  .lost-credentials {
    padding: 30px;
    padding-top: 0;;

    .lost-username {
      cursor: pointer;
      margin-top: 15px;
    }
    .lost-password {
      cursor: pointer;
    }
  }

  .error {
    color: #db2269;
    font-size: 0.625em;
    display: relative;
  }

  .invalid-form {
    color: #db2269;
    font-size: 1em;
    padding-bottom: 10px;
  }
}
