.privacies {
  display: flex;
  align-items: center;

  .container {
    padding-top: 180px;
    padding-bottom: 180px;

    @media (min-width: 768px) {
      padding-top: 250px;
      padding-bottom: 250px;
    }

    .card-body {
      color: #000;
    }
  }
}
