body {
  .modal-content {
    .error {
      color: #db2269;
      font-size: 0.625em;
      display: relative;
    }

    .invalid-form {
      color: #db2269;
      font-size: 1em;
      padding-bottom: 10px;
    }
  }
}
