.message {
  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    width: 100%;
  }
  .container {
    padding-top: 180px;
    padding-bottom: 180px;

    @media (min-width: 768px) {
      padding-top: 250px;
      padding-bottom: 250px;
    }
  }

  .error {
    color: #db2269;
    font-size: 0.625em;
    display: relative;
  }

  .invalid-form {
    color: #db2269;
    font-size: 1em;
    padding-bottom: 10px;
  }

  textarea {
    min-height: 280px;
  }

  .loading-spinner {
    padding-top: 100px;
    display: flex;
    justify-content: center;
  }
}
