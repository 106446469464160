.MuiPaper-root {
  background-color: #87ceeb !important;
  .menu-drawer {
    color: white;

    .MuiListSubheader-root {
      color: white;
      font-size: 1.2em;

      display: flex;
      justify-content: center;
      div {
        padding: 20px;
        font-size: 1.5rem;
        margin: 0;
        font-weight: bold;
      }
    }
  }
}
