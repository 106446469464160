.footer {
  display: flex;
  height: 150px;
  width: 100%;
  background-color: #70b8d4;
  color: white;
  opacity: 0.9;

  .footer-container {
    display: flex;
    align-items: center;
    justify-content: center;

    ul {
      padding: 0;
    }

    li {
      list-style-type: none;
      padding: 5px;

      a {
        cursor: pointer;
      }
    }
  }
}
