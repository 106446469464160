.App {
  height: 100%;
  overflow-x: hidden;

  .btn-primary {
    background-color: #70b8d4;
    border-color: #fff;
  }

  .btn-outline-primary {
    color: #70b8d4;
    border-color: #70b8d4;

    :hover {
      color: #fff !important;
      background-color: #70b8d4;
    }
  }

  .card {
    color: #70b8d4;
    border-radius: 0;
  }

  .card-header {
    color: #000;
    background-color: #fff;
  }
  .card-footer {
    color: #000;
    background-color: #fff;
  }

  .error {
    color: #db2269;
    font-size: 0.625em;
    display: relative;
  }

  .invalid-form {
    color: #db2269;
    font-size: 1em;
    padding-bottom: 10px;
  }
}
