.detail-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  .delete-button {
    position: absolute;
    top: 115px;
    right: 20px;

    @media (min-width: 786px) {
      top: 130px;
    }
  }

  .loading-spinner {
    padding-top: 100px;
    display: flex;
    justify-content: center;
  }

  .card-header {
    display: flex;
    padding-bottom: 8px;
    position: relative;

    padding: 5px;
    align-items: center;
    min-height: 40px;
    color: #000;

    .card-title {
      display: flex;
      flex: 1;
      font-weight: bold;
      padding-left: 20px;
      font-size: 1em;
      margin: 0;
      padding-left: 20px;
    }
    .card-subtitle {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;

      .image-container {
        display: flex;
        align-items: center;
        padding: 10px;

        img {
          width: 30px;
        }
      }
    }

    .view-counter {
      align-items: center;
      display: flex;
      color: rgb(163, 163, 163);
      div {
        p {
          margin: 0;
          padding-left: 5px;
        }
      }
    }
  }

  .card-body {
    color: #000;
  }

  .button-container {
    display: flex;
    flex-direction: row;
  }

  .detail-page-back-button {
    position: absolute;
    top: 180px;
    left: 20px;
  }

  .card-footer {
    padding-bottom: 8px;
    position: relative;

    padding: 0;
    align-items: center;
    min-height: 40px;
    p {
      margin: 0;
    }
    display: flex;
    flex-direction: column;
    @media (min-width: 420px) {
      flex-direction: row;
    }
    .card-text-with-icon {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      @media (min-width: 420px) {
        padding-left: 10px;
      }
      .contact-link {
        padding: 20px;
        text-decoration: none;
        color: #000;
        padding: 10px;
        cursor: pointer;
      }
    }
  }

  .detail-page-container {
    margin-top: 230px;
    padding-bottom: 200px;
  }

  .col {
    .share-text {
      display: flex;
      justify-content: center;
      padding-top: 30px;
    }

    .social-media {
      display: flex;

      justify-content: center;
      padding-top: 10px;
    }
  }
}
