$primary-text-color: #2a6d88;
$seconday-color: #c1a661;

.signup {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 130px;
  flex-direction: column;

  .footer {
    margin-top: 50px;
  }
  .loading-spinner {
    padding-top: 100px;
    display: flex;
    justify-content: center;
  }

  .card {
    color: $primary-text-color;
  }

  .card-header {
    color: $primary-text-color !important;
  }

  .row {
    justify-content: center;
  }

  .signup-headline {
    padding: 30px;
  }
  .signup-form {
    display: flex;

    input {
      outline: none;
    }
  }

  .error {
    color: #db2269;
    font-size: 0.625em;
    display: relative;
  }

  .invalid-form {
    color: #db2269;
    font-size: 1em;
    padding-bottom: 10px;
  }

  .submit-button {
    display: flex;
    justify-content: center;
    padding: 40px 10px;
    
    button {
      background-color: $seconday-color;
      border: 5px solid $seconday-color;
    }
  }

  .privacys {
    padding-top: 30px;
    div {
      padding: 7px;
    }
    a {
      padding-left: 20px;
      color: $primary-text-color;
    }
  }

  .signup-register {
    padding: 0 30px 20px;
    a {
      color: $primary-text-color;
      background-color: transparent;
      font-weight: bold;
    }
  }
}
