.main-page-header {
  padding: 0 10px;

  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 90px;
  position: fixed;
  z-index: 2;
  background-color: #70b8d4;
  color: white;
  opacity: 0.9;

  &.notch {
    height: 120px;
    padding: env(safe-area-inset-top) env(safe-area-inset-right)
      env(safe-area-inset-bottom) env(safe-area-inset-left);
  }

  @media (min-width: 768px) {
    height: 90px;
  }

  .main-page-headline-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 0.5;

    .main-page-headline {
      display: flex;
      justify-content: center;

      p {
        margin: 0;
        font-weight: bold;
        font-size: 0.8em;
        @media (min-width: 380px) {
          font-size: 1em;
        }
        @media (min-width: 575px) {
          font-size: 1.5em;
        }
      }
    }

    .main-page-subline {
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        color: white;
        font-size: 0.9rem;
        font-weight: 300;
        @media (min-width: 575px) {
          font-size: 1.4rem;
        }
      }

      .MuiList-root {
        li {
          color: white;
          font-size: 1.5em;
        }
      }
      .MuiListItemIcon-root {
        color: red;
        min-width: 0;
      }
      p {
        margin: 0;
        font-size: 0.8em;
        @media (min-width: 575px) {
          font-size: 1.8em;
        }
      }
    }
  }

  .header-image-container {
    display: flex;
    flex: 0.2;
    justify-content: center;
    .header-image {
      cursor: pointer;
      img {
        width: 65px;
        height: 65px;
      }
      &:hover {
        transform: scale(1.05);
        transition: 0.1s ease-in-out;
      }
      transform: scale(1);
      transition: 0.1s ease-in-out;
    }
  }

  .header-button-container {
    display: flex;
    flex: 0.3;
    justify-content: center;
    @media (min-width: 5750px) {
      flex: 0.2;
    }
  }
}
