.RangeSlider {
    width: 70%;
    margin-right: 10px;
    margin-top: 25px;
    margin-bottom: 25px;

    .range-slider__tooltip {
        z-index: 0;
        white-space: nowrap;
    }
}