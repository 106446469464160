.list {
  a {
    color: black;
    text-decoration: none;

    :hover {
      color: black;
      text-decoration: none;
    }
  }
}
