.list-item {
  padding: 8px;
  .card {
    color: #000 !important;

    cursor: pointer;
    .card-header {
      display: flex;
      padding-bottom: 8px;
      position: relative;

      padding: 5px;
      align-items: center;
      min-height: 40px;

      .distance-container {
        p {
          margin: 0;
          font-weight: lighter;
        }
      }

      .card-title {
        display: flex;
        flex: 1;
        font-weight: bold;
        font-size: 1em;
        margin: 0;
        padding-left: 20px;
      }
      .card-subtitle {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 0.3;

        .image-container {
          display: flex;
          align-items: center;
          padding: 10px;

          img {
            width: 30px;
          }
        }
      }
    }
  }
}
