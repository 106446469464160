.typeswitcher {
    display: block;
    width: 100%;
    margin-bottom: 20px;
}

.option {
    display: inline-block;
    width: 50%;
    margin: auto;

    p {
        text-align: center;
        vertical-align: bottom;
    }

    .help_active {
        background: url("/images/icons/help_active.png") no-repeat top;
        background-size: 80px;
    }

    .help_inactive {
        background: url("/images/icons/help_inactive.png") no-repeat top;
        background-size: 80px;
    }

    .give_active {
        background: url("/images/icons/give_active.png") no-repeat top;
        background-size: 80px;
    }

    .give_inactive {
        background: url("/images/icons/give_inactive.png") no-repeat top;
        background-size: 80px;
    }

    .icon {
        display: block;
        height: 85px;

        &:hover {
            transform: scale(1.02);
            transition: transform ease-in-out 0.2s;
        }
    }
}