.informations {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .button-container {
    display: flex;
    flex-direction: column;
    .button {
      display: flex;
      justify-content: center;
      padding: 10px;
    }
  }
}
