.leaflet-left {
  right: 0;
  bottom: 20px;
  left: auto;
  float: right;
}

.leaflet-control {
  margin: 10px;
}

.leaflet-container {
  margin-top: 15px;
  height: 290px;
  width: 100%;
  overflow: hidden;
}
