.landingpage {
  display: flex;
  justify-content: center;

  flex-direction: column;

  .image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../media/hero.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right bottom;
    height: 100vh;
    width: 100vw;

    .image-center {
      font-family: Montserrat, sans-serif;
      display: flex;
      align-items: center;
      flex-direction: column;

      .image-headline {
        text-align: center;
        margin: 0px 20px 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: white;

        .image-main-headline {
          display: flex;
          justify-content: center;
          padding-bottom: 20px;

          h1 {
            font-size: 2rem;
            letter-spacing: -0.15rem;
            font-weight: 600;
          }
        }

        .image-sub-headline {
          text-transform: uppercase;
          justify-content: center;

          h2 {
            font-size: 1rem;
            font-weight: 300;
          }
        }
      }

      .image-button {
        display: flex;
        justify-content: center;
        padding-top: 50px;

        button {
          width: 150px;
          height: 60px;
        }
      }
    }

    .image-bottom {
      color: #70b8d4;
      position: absolute;
      bottom: 60px;
      animation: pulse 2s infinite;
    }

    img {
      height: 100vh;
      width: 100vw;
    }
  }

  .divider {
    background-color: #1fc8db;
    height: 4px;
    width: 80px;
    margin: 30px auto;
  }

  .container {
    text-align: center;
    justify-content: center;
  }

  .content-container-left {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .content-container-right {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
  }

  .image-button-extra {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    button {
      background-color:#c1a661;
      width: 200px;
      height: 60px;
    }
  }

  .row {
    display: flex;

    p {
      font-size: 1.3em;
    }
  }

  .left {
    justify-content: flex-start;
  }

  .right {
    justify-content: flex-end;
  }

  .landingpage-container {
    margin-bottom: 80px;
  }

  .sub-header {
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: bold;
  }

  h4 {
    font-size: 1.3em;
    font-weight: lighter;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    color: transparent;
    bottom: 110px;
  }

  80% {
    transform: scale(1);
    color: #70b8d4;
  }

  100% {
    transform: scale(0.9);
    color: transparent;
    bottom: 20px;
  }
}