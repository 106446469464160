.location-button {
  // position: absolute;
  top: 115px;
  right: 20px;
  width: 50px;

  &.isIos {
    top: 155px;
  }

  @media (min-width: 786px) {
    top: 130px;
  }

  button {
    color: #212529;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    padding: 4px;
    margin-top: 28px;
  }

  .btn-primary {
    background-color: white !important;
  }
}