.spam-button {
  position: absolute;
  top: 115px;
  right: 20px;
  &.isIos {
    top: 155px;
  }
  @media (min-width: 786px) {
    top: 130px;
  }
}
