.custom-tooltip {
  position: fixed;
  flex-direction: row;
  display: flex;
  width: 100%;
  bottom: 20px;
  background-color: #70b8d4;
  border-radius: 6px;
  .custom-tooltip-icon {
    display: flex;
    align-items: center;
    .MuiSvgIcon-root {
      font-size: 2.3rem;
    }
  }

  .custom-tooltiptext {
    color: white;
    padding: 10px 0;
    padding-left: 10px;
    .custom-tooltip-img {
      width: 20px;
      padding: 5px;
      img {
        width: 20px;
      }
    }
    ::after {
      content: ' ';
      position: absolute;
      top: 100%; /* At the bottom of the tooltip */
      left: 50%;
      margin-left: -20px;
      border-width: 18px;
      border-style: solid;
      border-color: #70b8d4 transparent transparent transparent;
    }
  }
}
