.general-informations {
  .container {
    padding-top: 100px;
    padding-bottom: 180px;

    @media (min-width: 768px) {
      padding-top: 120px;
      padding-bottom: 250px;
    }
    .card {
      margin-bottom: 10px;
    }
    .card-body {
      color: #000;
    }

    .general-informations-headline {
      padding: 20px;
    }
  }
}
