.contact-form {
  position: relative;
  display: flex;
  .card-subtitle {
    font-weight: lighter;
    font-size: 1em;
    color: #000;
  }

  .container {
    padding-top: 180px;
    padding-bottom: 180px;
    display: flex;
    justify-content: center;

    @media (min-width: 768px) {
      padding-top: 250px;
      padding-bottom: 250px;
    }
  }

  .error {
    color: #db2269;
    font-size: 0.625em;
    display: relative;
  }

  .invalid-form {
    color: #db2269;
    font-size: 1em;
    padding-bottom: 10px;
  }
}
