.add-job {
  display: flex;
  justify-content: center;
  .loading-spinner {
    padding-top: 100px;
    display: flex;
    justify-content: center;
  }
  .row {
    justify-content: center;
  }

  .container {
    padding-top: 180px;
    padding-bottom: 180px;
    input[type='radio'] {
      width: 25px;
    }

    @media (min-width: 768px) {
      padding-top: 250px;
      padding-bottom: 250px;
    }

    .card-body {
      .input-description {
        min-height: 200px;
      }
    }
  }

  .error {
    color: #db2269;
    font-size: 0.625em;
    display: relative;
  }

  .invalid-form {
    color: #db2269;
    font-size: 1em;
    padding-bottom: 10px;
  }
}
