.layer-container {
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .layer {
    background-color: #fff;
    padding: 30px;
    width: 75vw;
    height: 75vh;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
    .layer-headline {
      font-weight: bold;
      font-size: 2em;
    }

    .close {
      position: absolute;
      right: 32px;
      top: 32px;
      width: 32px;
      height: 32px;
      opacity: 0.3;
      cursor: pointer;
      z-index: 100;
    }
    .close:hover {
      opacity: 1;
    }
    .close:before,
    .close:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 33px;
      width: 2px;
      background-color: #333;
    }
    .close:before {
      transform: rotate(45deg);
    }
    .close:after {
      transform: rotate(-45deg);
    }
    .infotext {
      p {
        margin-bottom: 15px;
      }
    }
  }
}
