.faq {
  display: flex;
  align-items: center;
  .container {
    padding-top: 200px;

    @media (min-width: 768px) {
      padding-top: 200px;
    }

    .card {
      margin-bottom: 20px;

      .card-header {
        font-weight: bold;
      }
    }
  }

  .faq-headline {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
    text-align: center;
    h1 {
      font-size: 2em;
      @media (min-width: 768px) {
        font-size: 3em;
      }
    }
  }
}
